import { env } from "@/config/env";
import { externalLinks } from "@/constants/links";

export const SOCIAL_LINKS = [
  {
    icon: "x",
    url: "https://twitter.com/mintology_app",
  },
  {
    icon: "linkedin",
    url: "https://www.linkedin.com/company/mintology/",
  },
];

export const LINKS = [
  // {
  //   title: "My Account",
  //   children: [
  //     {
  //       url: "/",
  //       title: "Create a store",
  //     },
  //     {
  //       url: "/",
  //       title: "List an Item for sale",
  //     },
  //     {
  //       url: "/",
  //       title: "My Profile",
  //     },
  //     {
  //       url: "/",
  //       title: "Browse",
  //     },
  //   ],
  // },
  // {
  //   title: "Need Help?",
  //   children: [
  //     {
  //       url: "/",
  //       title: "FAQ",
  //     },
  //     {
  //       url: "/",
  //       title: "Mintable Guide",
  //     },
  //   ],
  // },
  // {
  //   title: "Buy an Item",
  //   children: [
  //     {
  //       url: "/",
  //       title: "Digital Items",
  //     },
  //     {
  //       url: "/",
  //       title: "Stores",
  //     },
  //   ],
  // },
  {
    title: "Legal",
    children: [
      {
        url: externalLinks.privacyPolicy,
        title: "Privacy Policy",
      },
      {
        url: externalLinks.termsOfService,
        title: "Terms of Use",
      },
    ],
  },
];
