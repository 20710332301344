import { AwsSection } from "@/containers/AwsSection";
import CaseStudies from "@/containers/CaseStudies";
import ContactUs from "@/containers/ContactUs";
import Developers from "@/containers/Developers";
import Footer from "@/containers/Footer";
import Header from "@/containers/Header";
import CreditBanner from "@/containers/CreditBanner";
import HeroBanner from "@/containers/HeroBanner";
import Insights from "@/containers/Insights";
import Solutions from "@/containers/Solutions";
import UseCases from "@/containers/UseCases";
import Head from "next/head";
import Campaign from "@/containers/Campaign";

export default function Home() {
  return (
    <>
      <Head>
        <title>Mintology</title>
        <meta name="description" content="Mintology App" />
      </Head>
      <div className="text-neutral-900 relative">
        <Header />
        <main className="flex min-h-screen flex-col items-center justify-between">
          <HeroBanner />
          <CreditBanner />
          <UseCases />
          <AwsSection />
          <Solutions />
          <Developers />
          <CaseStudies />
          <ContactUs />
          <Insights />
        </main>
        <Footer />
      </div>
    </>
  );
}
