import React, { Fragment, useState, useEffect } from "react";

import clsx from "clsx";
import { Menu, Transition } from "@headlessui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { externalLinks } from "@/constants/links";

import { Requests, ExamplesEnum } from "./types";
import { apiList, npmCommand, mapKeyToDisplayValue } from "./constants";

import { Icon } from "@/components/Icon";
import CodeBlock from "@/components/CodeBlock";

const CodeSnippet = () => {
  const [currentRequest, setCurrentRequest] = useState<Requests>(Requests.cURL);
  const [currentKey, setCurrentKey] = useState<ExamplesEnum>(
    ExamplesEnum.Minting
  );
  const [displayValue, setDisplayValue] = useState<string>("");
  const [displayCode, setDisplayCode] = useState<string>("");
  const [isCodeCopied, setIsCodeCopied] = useState<boolean>(false);
  const [isExampleRequested, setIsExampleRequested] = useState<boolean>(false);
  const [currentExample, setCurrentExample] = useState<string>("");

  // called onLoad and when currentRequest and currentExample change
  useEffect(() => {
    const { request, response } = retrieveCode(currentKey, currentRequest);
    setIsExampleRequested(false);
    setDisplayCode(request);
    setCurrentExample(response);
    setDisplayValue(request);
  }, [currentKey, currentRequest]);

  const retrieveCode = (key: ExamplesEnum, requestType: Requests) => {
    let selectedKey = apiList.filter((each) => each.id === key);

    return {
      request: selectedKey[0].code[requestType].request,
      response: selectedKey[0].response || "",
    };
  };

  const onSelectExample = (key: ExamplesEnum) => {
    setCurrentKey(key);
  };

  const handleShowExample = () => {
    // append example to the end of displayCode
    setDisplayValue(
      (displayCode) =>
        `${displayCode} 
${currentExample}`
    );
    setIsExampleRequested(true);
  };

  const onClearExample = () => {
    setDisplayValue(displayCode);
    setIsExampleRequested(false);
  };

  return (
    <div className="font-monda rounded-[0.625rem] bg-neutral-900 text-white mt-8 lg:mt-12 overflow-hidden lg:max-w-[1000px] lg:mx-auto lg:flex relative z-20">
      <aside className="hidden lg:flex border-r-2 border-neutral-800 w-[262px]">
        <ul className="flex flex-col pt-11 min-w-[260px]">
          {Object.entries(ExamplesEnum).map(([key, value], j) => {
            const isSelected: boolean = key === currentKey;
            return (
              <li key={j} className="relative px-6">
                {isSelected && (
                  <div className="absolute left-0 top-0 h-full bg-primary-900 w-[6px] rounded"></div>
                )}
                <button
                  className={clsx(
                    "py-4 w-full text-left border-b border-neutral-800",
                    isSelected && "text-primary-900"
                  )}
                  onClick={() => onSelectExample(key as ExamplesEnum)}
                >
                  {mapKeyToDisplayValue[value]}
                </button>
              </li>
            );
          })}
        </ul>
      </aside>
      <div id="code-main" className="flex-1 w-full lg:max-w-[calc(100%-262px)]">
        <header className="pt-5 lg:pt-9 lg:pb-[1.125rem] lg:px-12 pb-[0.875rem] px-4 flex justify-between border-b-2 border-neutral-800 font-bold text-xs">
          <ul className="flex gap-[1.125rem] lg:gap-[5.56rem]">
            {Object.keys(Requests).map((request, i) => {
              return (
                <li
                  key={i}
                  className={clsx(
                    "pb-1 border-b-4 lg:text-lg lg:pb-2",
                    currentRequest == request
                      ? "border-primary-900"
                      : "border-transparent"
                  )}
                >
                  <button
                    onClick={() => setCurrentRequest(request as Requests)}
                  >
                    {request}
                  </button>
                </li>
              );
            })}
          </ul>
          <Menu as="div" className="lg:hidden">
            <Menu.Button className="flex gap-1">
              {currentKey} <Icon name="chevron-down" />
            </Menu.Button>
            <Menu.Items className="absolute right-0 mt-1 w-[11.25rem] origin-top-right divide-y bg-neutral-900 divide-neutral-800 border border-neutral-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col z-10">
              {Object.entries(ExamplesEnum).map(([key, value], k) => {
                return (
                  <Menu.Item key={k}>
                    <button
                      onClick={() => onSelectExample(key as ExamplesEnum)}
                      className="w-full py-[0.5rem] text-left px-2"
                    >
                      {value}
                    </button>
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Menu>
        </header>

        {currentRequest === Requests.Node && (
          <div className="pt-5 lg:px-6 pb-[0.875rem] lg:text-lg px-4 gap-2 text-xs border-b-2 border-neutral-800 flex flex-col lg:gap-6 lg:pb-5">
            <span className="lg:ml-6">Installation</span>
            <CodeBlock language={"bash"} code={npmCommand} />
          </div>
        )}

        {/* Request and Examples */}
        <div className="py-[0.875rem] lg:pt-10 lg:pb-6 px-4 lg:px-12 flex justify-between font-bold text-xs lg:text-lg">
          <span>Request</span>
          {!!currentExample.length && (
            <Menu>
              <Menu.Button className="flex gap-2">
                Examples <Icon name="chevron-down" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-6 lg:mt-10 w-[11.25rem] lg:w-56 origin-top-right divide-y bg-neutral-900 divide-neutral-800 border border-neutral-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    <button
                      className="w-full text-left lg:px-4 lg:py-2 py-[0.5rem] px-2"
                      onClick={handleShowExample}
                      disabled={isExampleRequested}
                    >
                      <span
                        className={clsx(isExampleRequested && "opacity-30")}
                      >
                        Request Example
                      </span>
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      disabled={!isExampleRequested}
                      className={clsx(
                        "w-full text-red-400 text-left lg:px-4 lg:py-2 py-[0.5rem] px-2"
                      )}
                      onClick={onClearExample}
                    >
                      <span
                        className={clsx(!isExampleRequested && "opacity-30")}
                      >
                        Clear Request
                      </span>
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>

        <div className="lg:px-6">
          <CodeBlock
            language={currentRequest === Requests.Node ? "javascript" : "bash"}
            code={displayValue}
            className="text-[0.8125rem] lg:text-base !py-3 !px-4 lg:rounded-lg"
          />
        </div>

        <footer className="p-4 flex justify-between items-center lg:pt-8 lg:px-6 lg:pb-8">
          <aside className="flex gap-2">
            <CopyToClipboard
              text={displayCode}
              onCopy={() => {
                setIsCodeCopied(true);
                setTimeout(() => {
                  setIsCodeCopied(false);
                }, 4000);
              }}
            >
              <Icon
                name={"document-duplicate"}
                size="xl"
                innerClassName={clsx(
                  "lg:w-[2rem] lg:h-[2rem] cursor-pointer text-white"
                )}
              />
            </CopyToClipboard>
            {isCodeCopied && (
              <span className="text-success-400 font-medium font-montserrat flex text-sm lg:text-base gap-2 items-center lg:gap[0.625rem]">
                Copied{" "}
                <Icon
                  name="check-fill"
                  innerClassName="lg:w-[1.2rem] lg:h-[1.2rem] text-base"
                />
              </span>
            )}
          </aside>
          <a
            className="inline-flex items-center justify-center gap-2 rounded-lg leading-4 bg-primary-900 text-white border border-primary-900 hover:bg-[#5D0FC3] hover:text-white hover:border-[#5D0FC3 disabled:bg-[#F7F7F7] disabled:border-[#F7F7F7] disabled:text-[#949494] disabled:cursor-not-allowed text-[1.125rem] text-xs py-1 px-2 lg:px-4 lg:py-2 font-semibold lg:text-base"
            href={externalLinks.documentation}
            target="_blank"
          >
            View Docs
          </a>
        </footer>
      </div>
    </div>
  );
};

export default CodeSnippet;
