export const settings = {
  arrows: false,
  dots: true,
  // autoplay: true,
  autoplaySpeed: 3000,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "160px",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        centerPadding: "50px",
      },
    },
  ],
  accessibility: true,
};

export const USE_CASES = [
  {
    title: "Membership",
    description:
      "NFTs allow you to offer more engaging and valuable membership programs, while also increasing the ease of access to your products, services, and other member benefits.",
    url: "https://blog.mintology.app/2023/08/17/how-nfts-are-transforming-memberships/",
    image: "/images/usecase_membership.webp",
    imageDesktop: "/images/usecase_membership_desktop.webp",
    backgroundImage: "/images/usecase_membership_bg.webp",
    tintClassName: "bg-tint-fuschia",
    icon: "membership",
  },
  {
    title: "Certificates",
    description:
      "Transparent, traceable, and secure - these are the key features of blockchain technology. From art, to certificates and KYC documents, NFTs make authentication easier and cheaper than ever before.",
    url: "https://blog.mintology.app/2023/08/17/nfts-future-of-authentication/",
    image: "/images/usecase_authentication.webp",
    imageDesktop: "/images/usecase_authentication_desktop.webp",
    backgroundImage: "/images/usecase_authentication_bg2.webp",
    tintClassName: "bg-primary-900",
    icon: "checkmark-shield",
  },
  {
    title: "Loyalty & Rewards",
    description:
      "Create gamified experiences that enhance engagement with any platform, environment, or campaign. Add more value through player-owned interoperable digital assets that unlock new possibilities.",
    url: "https://blog.mintology.app/2023/08/16/nfts-fueling-future-of-gamification/",
    image: "/images/usecase_gamification.webp",
    imageDesktop: "/images/usecase_gamification_desktop.webp",
    backgroundImage: "/images/usecase_gamification_bg.webp",
    tintClassName: "bg-tint-blue",
    icon: "game-controller",
  },
  {
    title: "Ticketing",
    description:
      "NFTs bring innovation to ticketing by creating secure and traceable digital tickets. Attendees can have confidence in the authenticity of their tickets, while event organizers can ensure better control over ticket distribution and access.",
    url: "https://blog.mintology.app/2023/08/17/nfts-reinventing-ticketing/",
    image: "/images/usecase_ticketing.webp",
    imageDesktop: "/images/usecase_ticketing_desktop.webp",
    backgroundImage: "/images/usecase_ticketing_bg.webp",
    tintClassName: "bg-tint-peach",
    icon: "ticket-stub",
  },
  {
    title: "New Media",
    description:
      "Gamification and NFTs have revolutionized gaming by introducing true ownership of in-game assets and enabling a thriving virtual economy where players can trade and earn valuable NFTs.",
    url: "https://blog.mintology.app/2023/08/17/nfts-reinventing-ticketing/",
    image: "/images/usecase_newmedia.webp",
    imageDesktop: "/images/usecase_newmedia.webp",
    backgroundImage: "/images/usecase_newmedia_bg.webp",
    tintClassName: "bg-tint-orange",
    icon: "virus",
  },
];
