import { AnimatedWrapper } from "@/components/AnimatedWrapper";
import { externalLinks } from "@/constants/links";
import clsx from "clsx";
import Image from "next/image";
import { useRef, useState } from "react";

type Props = {};

export function AwsSection({}: Props) {
  const [showVideo, setShowVideo] = useState(true);

  const videoRef = useRef<HTMLVideoElement>(null);

  const playVideo = () => {
    setShowVideo(true);
    videoRef.current?.play();
  };

  return (
    <section className="w-full max-w-[967px]">
      <div className="flex flex-col items-center gap-6 lg:gap-12 text-center lg:text-left mb-16 px-6 w-full lg:mb-28">
        <AnimatedWrapper className="text-3xl lg:text-5xl font-bold max-w-[80%] mx-auto text-center lg:max-w-[600px]">
          <span>
            <span className="text-primary-900">Mintology</span> is live on AWS
            Marketplace
          </span>
        </AnimatedWrapper>
        <div className="w-full relative aspect-[1267/711] ">
          <button
            onClick={() => playVideo()}
            className={clsx(
              "w-full items-center justify-center h-full",
              showVideo ? "hidden" : "flex"
            )}
          >
            <Image src="/images/aws-video-preview.webp" alt="AWS Logo" fill />
            <Image
              src="/images/play-icon.png"
              width={177}
              height={177}
              alt="Play icon"
              className="static z-10 w-[119px] lg:w-[177px] aspect-square"
            />
          </button>
          <video
            autoPlay
            muted
            ref={videoRef}
            className={clsx(
              "absolute inset-0 w-full h-full object-cover",
              showVideo ? "block" : "hidden"
            )}
            controls
          >
            <source src={externalLinks.awsPromoVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </section>
  );
}
