export enum Requests {
  cURL = "cURL",
  Node = "Node",
}

export enum ExamplesEnum {
  Minting = "Minting",
  Claiming = "Claiming",
  Premint = "Premint",
  Inventory = "Inventory",
  Authorize = "Authorize",
  CustodialWallet = "CustodialWallet",
}
