import SyntaxHighlighter from "react-syntax-highlighter";
// import hybrid from "react-syntax-highlighter/dist/esm/styles/hljs/hybrid";

import { CodeBlockProps } from "./types";

const CodeBlock = ({ code, language, className }: CodeBlockProps) => (
  <SyntaxHighlighter
    showLineNumbers
    language={language}
    // style={hybrid}
    className={className}
  >
    {code}
  </SyntaxHighlighter>
);

export default CodeBlock;
