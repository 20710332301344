export const SUBJECT_OPTIONS = [
  {
    label: "Sales",
    value: "sales",
  },
  {
    label: "Tech",
    value: "tech",
  },
  {
    label: "Others",
    value: "others",
  },
];
