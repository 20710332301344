import Image from "next/image";

import { Stats } from "./constants";

import Subtitle from "@/components/Subtitle";
import CodeSnippet from "@/components/CodeSnippet";
import { AnimationsEnum, AnimatedWrapper } from "@/components/AnimatedWrapper";

const Developers = () => {
  return (
    <section
      id="developers"
      className="bg-white px-6 pt-10 w-full lg:pt-[5.43rem] overflow-hidden relative"
    >
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col items-center gap-2 lg:gap-5 text-center">
          <AnimatedWrapper animationType={AnimationsEnum.FadeInDown}>
            <h2 className="font-bold text-3xl lg:text-7xl leading-normal">
              <span className="text-primary-900">Simple</span> To Use APIs
            </h2>
          </AnimatedWrapper>
          <Subtitle text="For Developers" />
          <AnimatedWrapper className="text-sm lg:text-lg leading-normal font-inter lg:max-w-[60%]">
            Make any platform or application fully NFT-integrated with just a
            few lines of code! Our suite of simple-to-use APIs were designed to
            support all necessary functions of your NFT strategy.
          </AnimatedWrapper>
        </div>
        <div className="relative">
          <div className="bg-insights-radial w-[1500px] h-[670px] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 invisible lg:visible" />
          <AnimatedWrapper className="relative z-10">
            <CodeSnippet />
          </AnimatedWrapper>
        </div>

        {/* stats */}
        <AnimatedWrapper
          tag={"ul"}
          className="flex flex-col gap-11 mt-12 max-w-[90%] mx-auto mb-11 lg:mt-14 lg:grid lg:grid-cols-3 lg:mb-[8.875rem] relative z-10"
        >
          {Stats.map((stat, i) => (
            <li
              key={i}
              className="flex flex-col lg:gap-5 text-center lg:text-left lg:justify-between"
            >
              <div className="flex flex-col lg:gap-5">
                <h3 className="text-primary-900 text-[2.81rem] font-bold leading-tight lg:text-3rem lg:h-[98px] lg:flex lg:justify-center lg:items-center lg:border-b-[3px] border-primary-900 lg:text-center">
                  {stat.figure}
                </h3>
                <h4 className="text-xl font-bold text-primary-600 mb-1 lg:mb-0 leading-tight lg:text-[1.375rem] font-inter">
                  {stat.title}
                </h4>
              </div>
              <p className="text-xs lg:text-base font-inter">{stat.subtitle}</p>
            </li>
          ))}
        </AnimatedWrapper>
        {/* mobile horizontal line */}
        <div className="w-[92px] h-[2px] mx-auto bg-primary-900 lg:hidden"></div>

        {/* backgrounds container */}
        <div id="developers-background">
          <Image
            src={"/images/background/World-Map 12.webp"}
            alt="World Map background mobile"
            className="absolute w-[287px] h-[350px] object-contain -top-[68px] -left-[71px] lg:invisible"
            width={20}
            height={100}
          />
          <Image
            src={"/images/background/World-Map 9.webp"}
            alt="World Map background desktop"
            className="absolute object-contain w-[888px] h-[507px] invisible lg:visible bottom-[7%] -left-[240px]"
            width={20}
            height={100}
          />
          <Image
            src={"/images/background/World-Map 8.webp"}
            alt="World Map background desktop 2"
            className="absolute object-contain w-[888px] h-[507px] invisible lg:visible top-[27%] -right-[240px]"
            width={20}
            height={100}
          />
        </div>
      </div>
    </section>
  );
};

export default Developers;
