import Image from "next/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { USE_CASES, settings } from "./constants";

import Subtitle from "@/components/Subtitle";
import UseCaseCard from "@/components/UseCaseCard";
import { AnimatedWrapper } from "@/components/AnimatedWrapper";

const UseCases = () => {
  return (
    <section
      id="use-cases"
      className="w-full flex text-neutral-900 bg-white relative pt-3 lg:pt-20 pb-36"
    >
      <div className="absolute -top-[68px]"></div>
      <div className="max-w-6xl mx-auto w-full">
        <div className="flex flex-col items-center gap-4 lg:gap-9 text-center lg:text-left mb-16 px-6">
          <AnimatedWrapper className="text-3xl lg:text-5xl font-bold">
            <span>
              <span className="text-primary-900">Redefining</span> possibilities
              with NFTs
            </span>
          </AnimatedWrapper>
          <Subtitle text="Use Cases" />
          <AnimatedWrapper
            delay={0.5}
            className="text-sm lg:text-lg text-center mt-1 lg:mt-0 leading-normal"
          >
            Discover how NFTs drive possibilities in new media, gamification,
            loyalty programs, ticketing, authentication, certification, and
            more.
          </AnimatedWrapper>
        </div>
        <AnimatedWrapper delay={0.3}>
          <div id="use-cases">
            <Slider {...settings}>
              {USE_CASES.map((useCase, i) => (
                <div key={i}>
                  <UseCaseCard {...useCase} />
                </div>
              ))}
            </Slider>
          </div>
        </AnimatedWrapper>

        {/* <div className="h-[400px] w-full absolute left-1/2 -translate-x-1/2 -bottom-[300px]">
          <Image
            src="/images/worldmap.webp"
            alt="World Map"
            fill
            className="object-cover"
          />
        </div> */}
      </div>
    </section>
  );
};

export default UseCases;
