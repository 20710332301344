import Image from "next/image";

import { WHITELISTING_ENABLED, env } from "@/config/env";

import Partners from "@/containers/Partners";

import { scrollToContactForm } from "@/utils/scrollToContactForm";

import { AnimatedWrapper, AnimationsEnum } from "@/components/AnimatedWrapper";
import { Button } from "@/components/Button";
import { useMemo } from "react";

const HeroBanner = () => {
  const isWaitlistEnabled = useMemo(() => {
    return WHITELISTING_ENABLED && env.WAITLIST_URL;
  }, []);

  return (
    <div className="relative bg-primary-800 w-full">
      <section className="justify-center max-w-7xl mx-auto w-full flex flex-col-reverse lg:flex-row items-center text-white lg:px-6 px-6 pt-36 pb-[3.875rem] lg:pb-[120px]">
        <Image
          src={"/images/vector_left.webp"}
          width={610}
          height={527}
          alt="Left Vector"
          className="absolute opacity-10 top-0 left-0"
          // priority
        />
        <Image
          src={"/images/vector_right.webp"}
          width={609}
          height={603}
          alt="Right Vector"
          className="opacity-0 absolute lg:opacity-10 bottom-0 right-0"
          // priority
        />
        <AnimatedWrapper
          animationType={AnimationsEnum.FadeInLeft}
          className="flex flex-col relative z-10 lg:w-[60%] gap-3 lg:gap-5 text-center lg:text-left"
        >
          <h1 className="lg:text-[2.625rem] text-3xl font-bold leading-tight">
            NFT APIs for Any Business Application
          </h1>
          <span className="text-sm lg:text-2xl leading-tight font-inter">
            Mint, token gate, transform, airdrop, and more with Mintology’s NFT
            APIs, built for any business application, to serve any target
            audience.
          </span>
          <div className="flex gap-5 lg:pr-20 lg:mt-10 mt-7 font-semibold whitespace-nowrap">
            <a
              aria-label={
                isWaitlistEnabled ? "Join the Waitlist" : "Launch App"
              }
              target="_blank"
              href={isWaitlistEnabled ? env.WAITLIST_URL : env.DASHBOARD_URL}
              className="inline-flex items-center justify-center gap-2 rounded-lg leading-4 bg-primary-900 text-white border border-primary-900 hover:bg-[#5D0FC3] hover:text-white hover:border-[#5D0FC3] disabled:bg-[#F7F7F7] disabled:border-[#F7F7F7] disabled:text-[#949494] disabled:cursor-not-allowed px-[2.875rem] py-[1rem] text-[1.125rem] w-1/2 text-xs lg:text-lg lg:max-h-[50px]"
            >
              {isWaitlistEnabled ? "Join the Waitlist" : "Launch App"}
            </a>
            <Button
              variant="primary"
              className="lg:w-1/3 w-full text-xs lg:text-lg lg:max-h-[50px] bg-[#3F26C6] border-[#3F26C6]"
              size="lg"
              onClick={scrollToContactForm}
            >
              Contact Sales
            </Button>
          </div>
        </AnimatedWrapper>
        <AnimatedWrapper
          animationType={AnimationsEnum.FadeInRight}
          className="relative z-1 pb-8"
        >
          <Image
            src={"/images/hero.webp"}
            width={620}
            height={666}
            alt="Slide 01"
            // priority
          />
        </AnimatedWrapper>
      </section>
      {/* appears on bottom */}
      <Partners />
    </div>
  );
};

export default HeroBanner;
