import clsx from "clsx";

type SubtitleProps = {
  text: string;
  className?: string;
  invert?: boolean;
};

const Subtitle = ({ text, className, invert }: SubtitleProps) => {
  return (
    <span
      className={clsx(
        "py-1 lg:py-[0.3125rem] px-8 text-base lg:text-[1.75rem] flex items-center justify-center mb-2 font-inter font-bold",
        className,
        invert ? "text-white" : "text-primary-900"
      )}
    >
      {text}
    </span>
  );
};

export default Subtitle;
