import React from "react";

import { useForm, ValidationError } from "@formspree/react";

import { FORMSPREE_FORM_ID } from "@/config/env";

import { SUBJECT_OPTIONS } from "@/constants/form";

import { Icon } from "@/components/Icon";
import { Button } from "@/components/Button";

const ContactUs = () => {
  const [state, handleSubmit] = useForm(FORMSPREE_FORM_ID);

  return (
    <div id="contact-us" className="bg-white relative overflow-hidden w-full">
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-primary-800 w-[200vw] h-[200vw] top-[87px] rounded-full"></div>
      <div id="contact-us-background">
        <div className="bg-insights-radial lg:w-[1774px] lg:top-[343px] lg:h-[757px] w-[611px] h-[261px] absolute top-[120px] left-1/2 -translate-x-1/2"></div>
      </div>
      <div className="relative z-10 max-w-7xl mx-auto px-10 pb-20 text-neutral-900">
        <div className="bg-white mx-auto lg:pt-12 lg:max-w-5xl rounded-lg overflow-hidden shadow-lg px-5 py-[1.375rem] md:px-7 md:py-8">
          <div className="lg:max-w-[69%] lg:mx-auto">
            <h2 className="font-bold text-lg md:text-2xl mb-3 lg:text-4xl">
              <span className="text-primary-900">Speak</span> to us!
            </h2>
            <span className="text-sm text-secondary-900 font-inter md:text-base">
              Interested in exploring solutions with Mintology? Please share a
              few details and our sales team will get back to you shortly!
            </span>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col items-start gap-4 lg:gap-6 text-sm lg:text-base mt-6"
            >
              <fieldset className="flex flex-col gap-2 w-full">
                <label className="text-secondary-900 font-medium">
                  Full Name*
                </label>
                <input
                  required
                  name="name"
                  placeholder="Type your name here"
                  type="text"
                  className="py-[0.375rem] px-2 lg:rounded-xl rounded-[0.3125rem] overflow-hidden border border-[#D2D2D2] lg:py-[0.875rem] lg:px-6"
                />
                <ValidationError
                  field="name"
                  prefix="Name"
                  errors={state.errors}
                />
              </fieldset>

              <fieldset className="flex flex-col gap-2 w-full">
                <label className="text-secondary-900 font-medium">
                  Company*
                </label>
                <input
                  required
                  name="company"
                  placeholder="Enter company here"
                  type="text"
                  className="py-[0.375rem] px-2 lg:rounded-xl rounded-[0.3125rem] overflow-hidden border border-[#D2D2D2] lg:py-[0.875rem] lg:px-6"
                />
                <ValidationError
                  field="company"
                  prefix="Company"
                  errors={state.errors}
                />
              </fieldset>

              <fieldset className="flex flex-col gap-2 w-full">
                <label className="text-secondary-900 font-medium">
                  Business Email*
                </label>
                <input
                  required
                  name="email"
                  placeholder="Enter Email here"
                  type="text"
                  className="py-[0.375rem] px-2 lg:rounded-xl rounded-[0.3125rem] overflow-hidden border border-[#D2D2D2] lg:py-[0.875rem] lg:px-6"
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </fieldset>

              <fieldset className="flex flex-col gap-2 w-full">
                <label
                  className="text-secondary-900 font-medium"
                  htmlFor="subject"
                >
                  Subject*
                </label>
                <select
                  id="subject"
                  name="subject"
                  required
                  className="py-[0.5rem] px-2 lg:rounded-xl rounded-[0.3125rem] overflow-hidden border border-[#D2D2D2] lg:py-[0.875rem] lg:px-6 placeholder:text-neutral-300"
                >
                  <option value="">Select</option>
                  {SUBJECT_OPTIONS.map((o, i) => (
                    <option key={i} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
                <ValidationError
                  prefix="Subject"
                  field="subject"
                  errors={state.errors}
                  className="text-sm text-red-500"
                />
              </fieldset>

              <fieldset className="flex flex-col gap-2 w-full">
                <label className="text-secondary-900 font-medium">
                  What do you want to achieve?*
                </label>
                <textarea
                  required
                  name="inquiry"
                  placeholder="How can we help?"
                  rows={6}
                  className="py-[0.375rem] px-2 lg:rounded-xl rounded-[0.3125rem] overflow-hidden border border-[#D2D2D2] lg:py-[0.875rem] lg:px-6"
                />
              </fieldset>
              <div className="flex flex-col gap-2 lg:flex-row lg:gap-6 justify-center mt-3 font-medium">
                <Button
                  variant="primary"
                  className="text-sm font-semibold"
                  disabled={state.submitting}
                >
                  Send
                </Button>
                {state.succeeded && (
                  <p className="text-success-600 flex items-center gap-2">
                    Form Submitted <Icon name="check-outlined" />
                  </p>
                )}
                {state.errors && (
                  <p className="text-red-600 flex items-center gap-2">
                    An error occurred! Please try again
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
