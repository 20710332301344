import React from "react";
import { env } from "@/config/env";

import Image from "next/image";
import Link from "next/link";

const headerText = "Unlock Your $7000 in Credits!";
const bodyText =
  "As a new member, you're entitled to $7,000 in credits, a sponsored smart contract, and personalized customer success support. Sign up now to learn more!";

export default function CreditBanner() {
  return (
    <>
      <div className="relative sm:hidden flex h-[240px] w-[400px] mt-12 mx-10 flex-col items-center justify-center">
        <div>
          <Image
            src={"/images/credit-banner-mobile.png"}
            fill
            alt="Credit Banner"
          />
        </div>
        <div className="absolute flex flex-col items-center h-[60px] w-4/5 text-center text-white mb-28">
          <span className="absolute text-xl mb-4 font-bold shadow-class">
            {headerText}
          </span>
          <span className="text-xl mb-4 font-bold credit-banner-header-text">
            {headerText}
          </span>
          <p className="sm:text-base text-sm mb-4">{bodyText}</p>
          <Link
            href={env.DASHBOARD_URL}
            className="z-10 rounded-md w-[120px] text-center bg-[#3cb6b0;] px-2 py-1text-white hover:bg-[#06CAC0;] hover:text-white disabled:opacity-50"
          >
            <button className="text-xs">Activate Now</button>
          </Link>
        </div>
      </div>
      <div className="relative hidden sm:flex h-[200px] sm:h-[350px] w-[400px] sm:w-[1100px] mt-12 mx-10 sm:mt-32 flex-col items-center justify-center sm:px-0">
        <div className="">
          <Image
            src={"/images/credit-banner-desktop.png"}
            fill
            alt="Credit Banner"
          />
        </div>
        <div className="absolute flex flex-col items-center h-[150px] w-4/5 justify-between text-center text-white mb-20">
          <span className="absolute text-lg sm:text-5xl mb-4 font-bold shadow-class">
            {headerText}
          </span>
          <span className="text-5xl mb-4 font-bold credit-banner-header-text">
            {headerText}
          </span>
          <p className="text-base mb-20">{bodyText}</p>
          <Link
            href={env.DASHBOARD_URL}
            className="z-10 rounded-md w-[250px] text-center bg-[#3cb6b0;] px-8 py-3 text-white hover:bg-[#06CAC0;] hover:text-white disabled:opacity-50"
          >
            <button className="text-base">Activate Now</button>
          </Link>
        </div>
      </div>
    </>
  );
}
