export const ARTICLES = [
  {
    url: "https://blog.mintology.app/2023/08/07/how-nfts-revolutionize-travel-lifestyle-industries/",
    image: "/images/insights_001.webp",
    title: "How Mintology Can Revolutionize Industries",
    description:
      "Changing landscape and changing age demographics means changing business needs. See how Mintology can revolutionize your industry.",
  },
  {
    url: "https://blog.mintology.app/2023/08/17/nfts-reinventing-ticketing/",
    image: "/images/insights_002x.webp",
    title: "Events & NFTs!",
    description:
      "A lot of our clients use NFTs to increase their presence at events or to drive more sponsors and engagement - at their own conferences.",
  },
  {
    url: "https://blog.mintology.app/2023/06/19/nft-authentication-digital-tokens-product-verification/",
    image: "/images/insights_003.webp",
    title: "NFT Authorize for Enterprises",
    description:
      "Using our APIs, Entreprises and SMEs can use simple token gating to authorize access into events, doors, discounts, VIP experiences and more.",
  },
  {
    url: "https://blog.mintology.app/2023/07/10/inspiring-loyalty-gaming-mintology-solutions-station-casinos/",
    image: "/images/insights_004.webp",
    title: "Integration in the real world",
    description:
      "With our simple APIs, any developer can get started with a few lines of code. See how one client rolled out over 1.5m NFTs to over 75,000 people aged 50-70 years old.",
  },
];
