import React from "react";

import Image from "next/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { PARTNERS, settings } from "./constants";

const Partners = () => {
  return (
    <div className="bg-white lg:rounded-[2.5rem] overflow-hidden lg:absolute shadow-lg pt-7 pb-8 max-w-[1108px] w-full flex flex-col items-center -bottom-[67.5px] lg:left-1/2 lg:-translate-x-1/2 z-10 gap-4">
      <h2 className="text-sm text-[#2E266F] font-inter">
        Trusted by Global Businesses
      </h2>
      <div className="flex items-center h-16 py-2 relative gap-4 w-full">
        <div className="h-full absolute left-0 w-1/3 bg-gradient-to-r from-white to-transparent z-10 invisible lg:visible"></div>
        <div className="h-full absolute right-0 w-1/3 bg-gradient-to-l from-white to-transparent z-10 invisible lg:visible"></div>
        <div id="partners" className="w-full h-full">
          <Slider {...settings}>
            {PARTNERS.map((partner, i) => (
              <div key={i}>
                <Image
                  src={partner.image}
                  alt={partner.alt}
                  className="object-contain mx-auto h-full w-auto"
                  width={120}
                  height={100}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Partners;
