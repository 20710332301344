import React from "react";
import { useState } from "react";

import clsx from "clsx";
import Image from "next/image";

import { CaseStudiesEnum } from "./types";
import { CaseStudiesArray } from "./constants";

import Subtitle from "@/components/Subtitle";
import { AnimatedWrapper, AnimationsEnum } from "@/components/AnimatedWrapper";

const CaseStudies = () => {
  const [currentCaseStudy, setCurrentCaseStudy] = useState<CaseStudiesEnum>(
    CaseStudiesEnum.mastercard
  );
  return (
    <section
      id="case-studies"
      className="bg-white px-6 pt-12 lg:pt-0 w-full pb-[4.375rem] relative"
    >
      <div className="max-w-7xl mx-auto relative z-10">
        <div className="flex flex-col items-center gap-2 lg:gap-5 text-center">
          <AnimatedWrapper animationType={AnimationsEnum.FadeInDown}>
            <h2 className="font-bold text-2xl lg:text-5xl leading-normal">
              Our Partners&apos;{" "}
              <span className="text-primary-900">Experience</span>
            </h2>
          </AnimatedWrapper>
          <Subtitle text={"Case Studies"} />
        </div>

        {/* case study cards */}
        <AnimatedWrapper className="lg:max-w-[85%] lg:mx-auto">
          <>
            {CaseStudiesArray.map((caseStudy, i: number) => {
              if (caseStudy.id !== currentCaseStudy) return;
              return (
                <div
                  key={i}
                  className="bg-white rounded-[0.625rem] overflow-hidden mt-8 lg:mt-[4rem] mb-5 shadow-lg lg:flex lg:mx-auto"
                >
                  <div className="aspect-[1.35] relative lg:aspect-square lg:w-[46%]">
                    <Image
                      src={caseStudy.image}
                      alt={caseStudy.title}
                      fill
                      className="object-cover object-top"
                    />
                  </div>
                  <div className="px-4 pb-6 flex flex-col lg:flex-col-reverse lg:justify-center lg:p-7 lg:w-[54%]">
                    <ul className="flex justify-around lg:justify-start lg:gap-8 pt-[1.125rem] mb-7 lg:mb-0">
                      {caseStudy.stats.map((partnerStat, i) => (
                        <li
                          key={i}
                          className="text-center flex flex-col gap-1 lg:max-w-[44%]"
                        >
                          <h3 className="text-primary-600 text-3xl font-bold">
                            {partnerStat.figure}
                          </h3>
                          <span className="text-xs lg:text-base font-inter font-medium">
                            {partnerStat.title}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <div className="flex items-start flex-col text-left gap-4">
                      <Image
                        src={caseStudy.logo}
                        alt={caseStudy.title}
                        className="hidden lg:flex mb-3 max-h-[150px]"
                        width={caseStudy.logo_w ? caseStudy.logo_w : 170}
                        height={100}
                      />
                      <h3 className="text-lg font-bold text-primary-600 lg:text-3xl">
                        {caseStudy.title}
                      </h3>
                      <p className="text-xs font-inter lg:text-base font-medium lg:mb-7">
                        {caseStudy.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* partner cards */}
            <ul className="flex lg:gap-10 flex-nowrap justify-center gap-3">
              {CaseStudiesArray.map((caseStudy, j) => (
                <li
                  key={j}
                  className={clsx(
                    "cursor-pointer py-4 lg:py-2 px-4 shadow-md rounded-[0.625rem] overflow-hidden bg-white flex items-center justify-center lg:aspect-[346/108] h-[45px] w-[105px] lg:w-[204px] lg:h-[60px] border",
                    caseStudy.id === currentCaseStudy
                      ? "border-primary-900"
                      : "border-transparent"
                  )}
                  onClick={() => setCurrentCaseStudy(caseStudy.id)}
                >
                  <Image
                    src={caseStudy.logo}
                    alt={caseStudy.title}
                    className="max-h-7 object-contain"
                    width={200}
                    height={100}
                  />
                </li>
              ))}
            </ul>
          </>
        </AnimatedWrapper>
      </div>
      <div id="case-studies-background">
        <Image
          src={"/images/background/World-Map 10.webp"}
          alt="Case Studies background"
          className="invisible lg:visible w-full max-w-[1600px] left-1/2 h-[920px] absolute top-[40%] -translate-x-1/2 object-contain"
          width={20}
          height={200}
        />
      </div>
    </section>
  );
};

export default CaseStudies;
