import { MINTOLOGY_API_ENDPOINT } from "@/constants/endpoints";

import { ExamplesEnum, Requests } from "./types";

export const mapKeyToDisplayValue = {
  [ExamplesEnum.Minting]: "Minting",
  [ExamplesEnum.Claiming]: "Claiming",
  [ExamplesEnum.Premint]: "Premint",
  [ExamplesEnum.Inventory]: "Inventory",
  [ExamplesEnum.Authorize]: "Authorize",
  [ExamplesEnum.CustodialWallet]: "Custodial Wallet",
};

type generateRequestType = {
  endpoint?: string;
  method?: string;
  requestType: Requests;
  sdkMethod?: string;
  nodeParams?: string;
};

export const npmCommand = "npm install @mintology/mintology";

export const generateRequest = ({
  endpoint,
  method = "GET",
  requestType,
  sdkMethod,
  nodeParams = "{projectKey: 'your_project_key'}",
}: generateRequestType) => {
  let request = "";

  switch (requestType) {
    case Requests.cURL:
      request = `curl --request ${method} \\
     --url "${MINTOLOGY_API_ENDPOINT.PROD}${endpoint}" \\
     --header 'accept: application/json' \\
     --header 'content-type: application/json' \\
     --header "X-Mintology-Private-API-Key: {your_api_key}"`;
      break;
    case Requests.Node:
      request = `const sdk = require('api')('@mintology-internal/v1.0#gtn12llerq1eg');
sdk.auth('your_api_key');
sdk.server('${MINTOLOGY_API_ENDPOINT.PROD}');
sdk.${sdkMethod}(${nodeParams})
  .then(({ data }) => console.log(data))
  .catch(err => console.error(err));`;
      break;
  }

  return request;
};

export const apiList = [
  {
    id: ExamplesEnum.Minting,
    title: "Minting API",
    response: `{
  "metadata": {
    "name": "string",
    "image": "string",
    "animation_url": "string",
    "description": "string",
    "attributes": [
      {
        "trait_type": "string",
        "value": "string"
      }
    ],
    "title": "string",
    "subtitle": "string"
  },
  "wallet_address": "string",
  "username": "string"
}`,
    code: {
      cURL: {
        request: generateRequest({
          endpoint: "/{your_project_key}/mint",
          method: "POST",
          requestType: Requests.cURL,
        }),
      },
      Node: {
        request: generateRequest({
          endpoint: "/{your_project_key}/mint",
          method: "POST",
          requestType: Requests.Node,
          sdkMethod: "mintMint",
        }),
      },
    },
  },
  {
    id: ExamplesEnum.Claiming,
    title: "Claiming API",
    response: `{
  "redemption_code": "string",
  "wallet_address": "string",
  "username": "string",
  "metadata": {
    "name": "string",
    "image": "string",
    "animation_url": "string",
    "description": "string",
    "attributes": [
      {
        "trait_type": "string",
        "value": "string"
      }
    ],
    "title": "string",
    "subtitle": "string"
  }`,
    code: {
      cURL: {
        request: generateRequest({
          endpoint: "/{your_project_key}/claim",
          method: "POST",
          requestType: Requests.cURL,
        }),
      },
      Node: {
        request: generateRequest({
          endpoint: "/{your_project_key}/claim",
          method: "POST",
          requestType: Requests.Node,
          sdkMethod: "mintClaim",
        }),
      },
    },
  },
  {
    id: ExamplesEnum.Premint,
    title: "Pre-Mint API",
    code: {
      cURL: {
        request: generateRequest({
          endpoint: "/{your_project_key}/premints",
          requestType: Requests.cURL,
        }),
      },
      Node: {
        request: generateRequest({
          endpoint: "/{your_project_key}/premints",
          requestType: Requests.Node,
          sdkMethod: "premintsList",
        }),
      },
    },
  },
  {
    id: ExamplesEnum.Inventory,
    title: "Inventory API",
    response: `{
  "wallet_address": "string",
  "contract_address": "string"
}`,
    code: {
      cURL: {
        request: generateRequest({
          endpoint: "/{your_project_key}/authorize/inventory",
          method: "POST",
          requestType: Requests.cURL,
        }),
      },
      Node: {
        request: generateRequest({
          endpoint: "/{your_project_key}/authorize/inventory",
          method: "POST",
          requestType: Requests.Node,
          sdkMethod: "authorizeInventory",
        }),
      },
    },
  },
  {
    id: ExamplesEnum.Authorize,
    title: "Authorize API",
    response: `{
  "contract_address": "string",
  "token_id": "string",
  "wallet_address": "string"
}`,
    code: {
      cURL: {
        request: generateRequest({
          endpoint: "/{your_project_key}/authorize",
          method: "POST",
          requestType: Requests.cURL,
        }),
      },
      Node: {
        request: generateRequest({
          endpoint: "/{your_project_key}/authorize",
          method: "POST",
          requestType: Requests.Node,
          sdkMethod: "authorize",
        }),
      },
    },
  },
  {
    id: ExamplesEnum.CustodialWallet,
    title: "Custodial Wallet",
    response: `{
  "email": "string",
  "username": "string"
}`,
    code: {
      cURL: {
        request: generateRequest({
          endpoint: "/custodial-wallets",
          method: "POST",
          requestType: Requests.cURL,
        }),
      },
      Node: {
        request: generateRequest({
          endpoint: "/custodial-wallets",
          method: "POST",
          requestType: Requests.Node,
          sdkMethod: "custodialWalletsCreate",
          nodeParams: "",
        }),
      },
    },
  },
];
