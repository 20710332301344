import React from "react";

import clsx from "clsx";
import Link from "next/link";
import Image from "next/image";

import { LINKS, SOCIAL_LINKS } from "./constants";

import { Icon, IconName } from "@/components/Icon";

const Footer = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  return (
    <footer
      id="footer"
      className="w-full pt-11 pb-[1.75rem] bg-neutral-700 text-[#fbfafc] lg:pt-[4.31rem] lg:pb-[3rem]"
    >
      <div className="max-w-7xl mx-auto px-11 flex flex-col lg:flex-row justify-between lg:gap-16">
        <div className="flex flex-col items-center lg:items-start lg:max-w-[230px] lg:w-1/3">
          <Image
            src={"/images/mintology-logo-new.webp"}
            alt="Mintology logo"
            width={164}
            height={26}
            className="lg:w-[201px] lg:h-[37px] lg:mb-9 mb-8 object-contain"
          />
          <span className="text-sm lg:text-base mb-3">Join our community</span>
          <ul className="mb-10 flex gap-4 lg:gap-[1.375rem]">
            {SOCIAL_LINKS.map((link, l) => (
              <li key={l}>
                <Link href={link.url} target="_blank" aria-label={link.url}>
                  <Icon name={link.icon as IconName} size="lg" />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div
          id="footer-links"
          className="grid gap-10 lg:flex lg:flex-col lg:justify-between lg:items-end lg:flex-1"
        >
          {LINKS.map((linkGroup, i) => (
            <div
              key={`link${i}`}
              className="flex flex-col lg:flex-row lg:items-center gap-[1.4rem] lg:gap-8"
            >
              <h4 className="hidden lg:flex text-base font-bold leading-normal">
                {linkGroup.title}
              </h4>
              <ul
                className={clsx("flex flex-row gap-5 justify-center lg:gap-5")}
              >
                {linkGroup.children.map((child, j) => (
                  <li key={`child${j}`}>
                    <Link
                      aria-label={child.url}
                      href={child.url}
                      className="text-[0.796rem] font-medium lg:text-sm lg:hover:underline"
                    >
                      {child.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <span className="text-xs block text-center">
            @{currentYear} Mintology
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
