export const Stats = [
  {
    figure: "Fortune 500",
    title: "Companies Using Our APIs",
    subtitle:
      "Large international brands use Mintology everyday to power their web3 and NFT initiatives. Our clients speak for themselves, with many listed on NASDAQ, and Singapore Exchange. We work with SMEs to multi-billion dollar enterprises.",
  },
  {
    figure: "$58mil",
    title: "USD saved in Ethereum gas fees",
    subtitle:
      "Mintology is the only company on the planet who can mint NFTs on Ethereum without a single transaction. No strings attached, a full-fledged ERC-721 standard that can be instantly used. Create millions of NFTs on Ethereum for zero gas fees.",
  },
  {
    figure: "75+",
    title: "Different APIs to call to develop your project",
    subtitle:
      "From SSO login, to automatic creation of custodial wallets, free claimable NFTs, dynamic NFTs, and even integration into an existing POS/IoT system - our APIs are so powerful that any business can easily integrate them for any NFT use case.",
  },
];
