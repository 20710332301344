import { CaseStudiesEnum } from "./types";

export const CaseStudiesArray = [
  {
    id: CaseStudiesEnum.mastercard,
    logo: "/images/partners/mastercard.webp",
    title: "NFTs for Major Conference Engagement Boosts",
    image: "/images/Converge.webp",
    logo_w: 130,
    description:
      "Mastercard - Mastercard collaborated with Mintology to counter declining visitor engagement at its events. Using Mintology's innovative NFT solutions, including an NFT Scavenger Hunt, attendees engaged with QR codes to collect unique NFTs, with the chance of winning exclusive prizes. While initial expectations predicted a 10-20% NFT claim rate, the actual engagement soared to 50-60%.",
    stats: [
      {
        figure: "65%",
        title: "Increased foot traffic",
      },
      {
        figure: "4",
        title: "Major Conferences using NFTs",
      },
      {
        figure: "60%",
        title: "All NFTs claimed",
      },
    ],
  },
  {
    id: CaseStudiesEnum.stationx,
    logo: "/images/partners/station_casinos.webp",
    title: "Charms directly displayed and integrated into retail locations",
    image: "/images/stationx.webp",
    description:
      "Station Casinos partnered with Mintology to enhance its loyalty program using NFT technology. They introduced STN Charms, digital collectibles for loyalty members that added excitement to their gaming experience. Within two months, this initiative resulted in a 700% increase in member engagement, positioning Station Casinos as an industry innovator and emphasizing their commitment to customer experience.",
    stats: [
      {
        figure: "237k",
        title: "New NFT Users",
      },
      {
        figure: "700%",
        title: "NFT Users Increase",
      },
      {
        figure: "1.5mil+",
        title: "NFTs Minted",
      },
    ],
  },
  {
    id: CaseStudiesEnum.mediacorp,
    logo: "/images/partners/mediacorp2.webp",
    title: "Mintology solutions enable Mediacorp to sell web3 solutions",
    image: "/images/mediacorp.webp",
    description:
      "Mediacorp, Singapore's top media network, partnered with Mintology for their 2023 Lunar New Year Luncheon to offer unique Happy Bunny NFTs to attendees, which granted year-long exclusive perks. Alongside the digital tokens, limited physical bunny figurines were distributed. Mintology ensured a user-friendly NFT claim process with a 85% success rate, highlighting the potential of NFTs in boosting audience engagement for events.",
    stats: [
      {
        figure: "85%",
        title: "Redemption rate",
      },
      {
        figure: "10+",
        title: "Clients approached offering end-to-end web3 solutions",
      },
    ],
  },
  {
    id: CaseStudiesEnum.comiccon,
    logo: "/images/partners/comic_con.webp",
    title: "Singapore Comic Convention",
    image: "/images/comic_con1.webp",
    description:
      "We partnered with Singapore Comic-Con, Singapore’s largest comic convention, as its official NFT provider for SGCC 2023. Without any prior Web3 knowledge, attendees were able to participate in collecting a series of free NFTs in a scavenger hunt, and claim NFT images taken at the official SGCC photo booth. Mintology’s solutions were a resounding success, with the scavenger hunt hunt selling out of prizes on both days, demonstrating the impact NFTs can have at large-scale events.",
    stats: [
      {
        figure: "60%",
        title: "Completion Rate",
      },
      {
        figure: "1.6K",
        title: "NFTs minted",
      },
    ],
  },
  {
    id: CaseStudiesEnum.sanctuary,
    logo: "/images/partners/sanctuary.webp",
    title: "iSanctuary",
    image: "/images/sanctuary1.webp",
    description:
      "We worked with investigation experts iSanctuary to launch an NFT embedding a Worldwide Freezing Order (WFO) from the Singapore High Court that targets the recovery of stolen cryptocurrency. Utilizing Soulbound token technology, this NFT is irrevocably linked to the culprits' wallets, making any unauthorized transactions visible. The NFT, non-transferable and permanent, contains all relevant court documents and enables iSanctuary investigators to enforce the court's orders efficiently.",
    stats: [
      {
        figure: "$3m",
        title: "Crypto assets recovered with help of NFTs",
      },
    ],
  },
];
