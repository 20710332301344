import React from "react";

import Image from "next/image";

import Subtitle from "@/components/Subtitle";
import { AnimatedWrapper } from "@/components/AnimatedWrapper";

const Solutions = () => {
  return (
    <section id="solutions" className="relative w-full overflow-hidden ">
      <div id="solutions-background">
        <AnimatedWrapper duration={0.7}>
          <div className="rounded-full left-1/2 -translate-x-1/2 overflow-hidden lg:w-[300vw] lg:h-[300vw] bg-primary-800 absolute top-0 h-[2000px] w-[2000px]"></div>
        </AnimatedWrapper>
        {/* mobile elements */}
        <div className="opacity-30">
          <Image
            src={"/images/background/solutions-polygon-mobile.webp"}
            alt="Polygon mobile background"
            className="absolute w-[146px] h-[152px] right-0 top-[50px] sm:w-[350px] sm:h-[370px] lg:invisible"
            width={146}
            height={152}
          />
          <Image
            src={"/images/background/solutions-polygon-mobile2.webp"}
            alt="Polygon mobile background"
            className="absolute w-[236px] object-contain h-[300px] right-0 -bottom-[50px] sm:w-[350px] sm:h-[370px] lg:invisible opacity-50"
            width={236}
            height={300}
          />
          <div
            className="bg-insights-radial w-[737px] h-[522px] absolute
          bottom-0 z-10 left-1/2 -translate-x-1/2 lg:w-[1000px] lg:h-[1000px] rounded-full"
          ></div>
          {/* desktop elements */}
          <Image
            src={"/images/background/solutions-polygon.webp"}
            alt="Polygon background"
            className="absolute invisible lg:visible w-[600px] h-[600px] right-0 top-0"
            width={600}
            height={600}
          />
          <Image
            src={"/images/background/solutions-polygon2.webp"}
            alt="Polygon background"
            className="absolute invisible lg:visible w-[600px] h-[600px] left-0 bottom-0 opacity-50"
            width={600}
            height={600}
          />
        </div>
      </div>
      <div className="flex relative z-10 text-white flex-col gap-3 md:pt-[5rem] lg:gap-5 items-center pt-12 max-w-7xl mx-auto lg:pt-24">
        <AnimatedWrapper>
          <h2 className="text-2xl font-bold text-center md:text-3xl lg:text-5xl leading-normal">
            Why Choose <br className="md:hidden" />
            <span className="text-primary-900">Mintology</span>
          </h2>
        </AnimatedWrapper>
        <Subtitle text="Our Solutions" invert />
        <ul className="w-full flex flex-col gap-6 px-9 pt-12 pb-[8.625rem] lg:grid lg:grid-cols-col-14 lg:gap-8 lg:max-w-[90%] mx-auto">
          {/* gasless minting */}
          <AnimatedWrapper
              tag="li"
              className="rounded-lg lg:rounded-[1.31rem] overflow-hidden bg-primary-700 pt-[1.125rem] px-3 pb-0 flex flex-col text-center gap-1 lg:flex-row-reverse lg:col-span-9 lg:gap-11 lg:pl-0 relative">
            <div className="gap-1 flex flex-col lg:justify-center lg:gap-5 lg:w-1/2 lg:text-left relative z-20">
              <h3 className="text-lg font-bold lg:text-[1.5rem]">
                Gasless Minting
              </h3>
              <p className="text-xs lg:text-base font-inter">
                With our proprietary gasless minting technology, we’ve
                eliminated over 90% of the costs of minting NFTs, allowing
                businesses the flexibility to launch their own NFT strategy.
              </p>
            </div>
            <div className="bg-solutions-radial invisible lg:visible absolute w-[1007px] left-1/4 top-1/2 -translate-x-1/2 -translate-y-1/2 h-[1007px] rounded-full" />
            <Image
              src={"/images/solutions/gasless_minting3.webp"}
              width={208}
              height={234}
              alt="Gasless Minting"
              className="mx-auto mt-3 object-contain lg:w-1/2 lg:object-bottom z-10"
            />
          </AnimatedWrapper>
          {/* custodial wallet */}
          <AnimatedWrapper
              tag="li"
            delay={0.3}
            className="rounded-lg lg:rounded-[1.31rem] lg:pl-8 relative overflow-hidden bg-primary-600 pl-4 flex justify-between lg:col-span-5"
          >
            <aside className="flex flex-col gap-2 justify-center relative z-10">
              <h3 className="text-lg font-bold leading-tight lg:text-[1.5rem]">
                Custodial Wallet
              </h3>
              <p className="text-xs lg:text-base font-inter">
                Our integrated custodial wallet solution means users don’t have
                to worry about private keys - making onboarding super easy.
              </p>
            </aside>
            <div className="absolute w-[9.37rem] h-[9.37rem] lg:w-[325px] lg:h-[325px] bg-integrated-radial -right-[100px] top-1/2 -translate-y-1/2"></div>
            <Image
              src={"/images/solutions/custodial_wallet2.webp"}
              width={151}
              height={167}
              alt="Custodial Wallet"
              className="object-contain relative z-10"
            />
          </AnimatedWrapper>
          {/* easy onboarding */}
          <AnimatedWrapper
              tag="li"
              className="rounded-lg lg:rounded-[1.31rem] overflow-hidden bg-primary-700 pr-4 flex justify-between items-center gap-4 lg:col-span-8 lg:flex-row-reverse lg:pr-0 lg:pl-11">
            <Image
              src={"/images/solutions/easy_onboarding3.webp"}
              alt="Easy Onboarding"
              height={185}
              width={140}
              className="object-contain lg:w-1/2 lg:-mr-3 h-full"
            />
            <aside className="flex flex-col gap-2 text-left lg:w-1/2 py-8">
              <h3 className="text-lg font-bold lg:text-[1.5rem] leading-tight">
                Easy Customer Onboarding
              </h3>
              <p className="text-xs lg:text-base font-inter">
                With our one-click account and custodial wallet setup, users can
                go from 0 to Web3 in just a few seconds! No friction, and no
                fuss.
              </p>
            </aside>
          </AnimatedWrapper>

          {/* full control & flexibility */}
          <AnimatedWrapper
              tag="li"
            delay={0.3}
            className="rounded-lg lg:rounded-[1.31rem] overflow-hidden bg-primary-600 text-center pt-4 px-4 lg:col-span-6 lg:flex lg:flex-col lg:justify-between lg:pt-7 relative"
          >
            <div className="lg:gap-3 flex gap-1 flex-col relative z-10">
              <h3 className="text-lg font-bold lg:text-[1.5rem]">
                Full Control & Flexibility
              </h3>
              <p className="text-xs lg:text-base font-inter">
                Mintology offers full customization and control over your smart
                contract and NFTs. If it’s possible, we can do it!
              </p>
            </div>
            <div className="absolute w-[403px] h-[290px] bg-control-radial left-1/2 -translate-x-1/2"></div>
            <Image
              src={"/images/solutions/full_control.webp"}
              width={267}
              height={173}
              alt="Full Control & Flexibility"
              className="mx-auto mt-4 object-contain lg:w-[80%] relative z-10"
            />
          </AnimatedWrapper>
        </ul>
      </div>
    </section>
  );
};

export default Solutions;
