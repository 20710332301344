import React from "react";

import clsx from "clsx";
import Link from "next/link";
import Image from "next/image";

import { UseCaseCardProps } from "./types";
import { Icon, IconName } from "@/components/Icon";

const UseCaseCard = ({
  title,
  description,
  url,
  image,
  imageDesktop,
  backgroundImage,
  tintClassName,
  icon,
}: UseCaseCardProps) => {
  return (
    <div className="relative text-white px-5 md:px-5 h-full">
      {/* overlapping image on mobile */}

      <Image
        src={image}
        alt={`${title} mobile`}
        className="mx-auto -mb-[4.7rem] relative z-20 md:!hidden h-[124px] w-auto"
        width={200}
        height={200}
      />
      <div
        className={clsx(
          "relative h-full z-10 pt-[100px] md:py-10 md:px-11 px-[1.125rem] pb-[1.125rem] rounded-2xl overflow-hidden md:flex",
          tintClassName
        )}
      >
        <Image
          src={backgroundImage}
          alt={`${title} background`}
          fill
          className="object-cover overflow-hidden inset-0 opacity-50"
        />
        <div className="gap-[1.125rem] md:text-left text-center flex flex-col relative z-10 md:w-[72%] md:gap-7 lg:justify-center lg:items-start">
          <h2 className="text-xl font-bold md:text-4xl flex items-center justify-center gap-2 lg:gap-5">
            {title}{" "}
            <Icon
              name={icon as IconName}
              size="xl"
              innerClassName="lg:w-[2.2rem] lg:h-[2.2rem]"
            />
          </h2>
          <p className="text-xs md:text-base leading-normal">{description}</p>
          <a
            href={url}
            aria-label="Read More"
            className="text-sm md:text-lg font-semibold flex gap-2 lg:gap-3 text-center justify-center"
            target="_blank"
          >
            Read Our Detailed Guide on {title}. <Icon name="arrow-diagonal" />
          </a>
        </div>
        <Image
          src={imageDesktop}
          alt={`${title} main`}
          width={272}
          height={302}
          className="!hidden md:!flex absolute z-10 right-0 max-w-[30%]"
        />
      </div>
    </div>
  );
};

export default UseCaseCard;
