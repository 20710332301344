export const PARTNERS = [
  { alt: "supercharge", image: "/images/partners/supercharge.webp" },
  { alt: "esbaar smart solutions", image: "/images/partners/esbaar.webp" },
  { alt: "hellovacay", image: "/images/partners/hellovacay.webp" },
  { alt: "mediacorp", image: "/images/partners/mediacorp2.webp" },
  { alt: "mastercard", image: "/images/partners/mastercard.webp" },
  { alt: "immutable_x", image: "/images/partners/immutable_x.webp" },
  { alt: "station_casinos", image: "/images/partners/station_casinos.webp" },
  { alt: "aws", image: "/images/partners/aws.webp" },
];

export const settings = {
  arrows: false,
  dots: false,
  speed: 3000,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 5,
  slidesToScroll: 1,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};
